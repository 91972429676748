.gamebutton {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #fff;
    background: linear-gradient(180deg, rgb(46 210 214), rgb(228 27 238));
    transition: 0.5s;
    font-weight: 500;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.animatebutton {
    padding: 15px 20px 10px 20px;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle), #0d6efd, #0d6efd 5%, #0d6efd 60%, #0d6efd 95%);
    border: solid 2px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #ffffff 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
}

@-webkit-keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

.animatebutton:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

.gamebutton:hover {
    transform: scale(1.1);
}

.common-game-bgn-container {
    position: relative;
    width: 100%;
    min-height: "400px";
    height: 'auto'
}

.common-game-bg {
    filter: blur(20px);
    width: 100%;
    height: 600px;
    background-position: bottom;
    box-shadow: inset 0 0 0 2000px rgba(28, 28, 28, 0.7);
}

.common-game-bg-overlay-banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.common-game-bg-overlay-banner .center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.common-game-bg-overlay-banner .center-content img {
    height: 200px;
    width: 300px;
    border-radius: 10px;
    /* animation-name: ZoomInOut;
    animation-duration: 2s;
    animation-iteration-count: infinite; */
}

.description {
    font-size: 18px;
}

.tag {
    border-radius: 10px;
    padding: 5px 20px;
    color: white;
    border: 1px solid;
}

.tag:hover {

    color: rgb(46 210 214);
    border: 1px solid;
}

.mb-5 {
    margin-bottom: 2rem !important;
}

@media only screen and (max-width: 1000px) {
    .bannerImg {
        width: 100%;
    }

    .description {
        font-size: 15px;
    }

    .tag {
        font-size: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .bannerImg {
        height: 300px;
    }

    .description {
        font-size: 12px;
    }

    .tag {
        font-size: 12px;
    }
}

@keyframes ZoomInOut {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.main-container {
    width: 63%;
    margin: 0 auto !important;
}


@media only screen and (max-width: 600px) {
    .main-container {
        width: 100%;
    }

    .common-game-bgn-container {
        position: relative;
        width: 100%;
        min-height: "300px";
        height: 'auto'
    }

    .common-game-bg {
        filter: blur(20px);
        width: 100%;
        height: 300px;
        background-position: bottom;
        box-shadow: inset 0 0 0 2000px rgba(28, 28, 28, 0.7);
    }

    .common-game-bg-overlay-banner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.adbutton {
    padding: 6px 20px;
    border-radius: 9999px;
    border: 1px solid #fff;
    background: rgb(51 144 255);
    transition: 0.5s;
    font-weight: 500;
    color: white;
    line-height: 26px;
}

.adbutton:hover {
    transform: scale(1.1);
}

.mt-m-1 {
    margin-top: -0.25rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}