.bgContent {
  /* background-image: url(/src/assets/images/backgroud.jpg); */
  /* background-image: url(/src/assets/images/backgroud.png); */
  /* min-height: calc(100vh);
  position: relative;
  z-index: 10;
  padding-top: 5rem;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  height: auto !important;
  background-color: #030d17;
  padding-bottom: 2rem; */
  padding: 20px;
  border-radius: 20px;
  /* border: 2px solid #808080; */
  /* background-color: #252525; */
}

.gameHeader {
  background: linear-gradient(#495057 0%, #495057 53%, #000000 0%, #000000 100%);
}

.offcanvas {
  max-width: 70%;
}

.offcanvas.offcanvas-start {
  background-color: #495057 !important;
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
  --bs-btn-close-opacity: 0.5;
}

.fontheigh {
  font-size: 18px;
  line-height: 1.5;
  color: #e0e0e0;
  font-weight: normal;
  padding-bottom: 1rem;
}

.gametitle {
  font-weight: bold;
  /* text-decoration: underline; */
  line-height: 1.5;
  color: #fff;
}

body {
  /* background: linear-gradient(#495057 0%, #495057 2.5%, #000000 0%, #000000 100%); */
  background-color: #000000;
  font-family: Montserrat, sans-serif;
}

.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #313131;
}

.fs-1 {
  font-size: 1px;
}

.fs-2 {
  font-size: 2px;
}

.fs-3 {
  font-size: 3px;
}

.fs-4 {
  font-size: 4px;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.descHeading {
  /* background: linear-gradient(180deg, rgb(78 190 193), rgb(235 92 242)); */
  background: linear-gradient(180deg, rgb(111 122 133), rgb(75 83 90));
  border-radius: 5px;
  padding: 10px;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  /* height: calc(100% + 45px); */
  height: 100vh;
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-button {
  background-color: #ddd;
}


@media only screen and (max-width: 1000px) {
  .iframe-container iframe {
    height: calc(100%);
  }
}

.errorMessage {
  color: #ff3535;
}

.data {
  transition: all .4s;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  position: absolute;
  left: 50%;
  bottom: -100%;
  transform: translate(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.backButton {
  position: fixed;
  left: 2%;
  top: 50%;
}

.backButton img {
  border-radius: 6px;
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.scrollTop {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
  display: none;
  cursor: pointer;
}

.modal-dialog {
  min-height: calc(100% - var(--bs-modal-margin)* 2) !important;
}