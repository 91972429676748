.gameCard {
    color: rgba(255, 255, 255, 0.623);
    position: relative;
    overflow: hidden;
}

.gameCard img {
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
    width: 100%;
    /* height: 130px; */
    height: auto;
}

.gameCard img:hover {
    transform: scale(1);
    border-radius: 20px;
}

.king {
    transition: all .4s;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    position: absolute;
    left: 50%;
    bottom: -100%;
    transform: translate(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 100%;
    text-align: center;
    z-index: 2;
    color: #fff;
}

.gameCard:hover .king {
    bottom: 8px;
}

.gameCard:hover .databg {
    opacity: 1;
}

.databg {
    transition: all .4s;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translate(-50%);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background: linear-gradient(0deg, #000000e0, #0000002e 60%, #00000008);
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}


@media only screen and (max-width: 992px) {
    .gameCard img {
        /* height: 130px; */
        height: auto;
    }

    body {
        background: linear-gradient(#495057 0%, #495057 1%, #000000 0%, #000000 100%);
        font-family: Montserrat, sans-serif;
    }

    .fontheigh {
        font-size: 14px;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 768px) {
    .gameCard img {
        /* height: 120px; */
        height: auto;
    }

    body {
        background: linear-gradient(#495057 0%, #495057 1%, #000000 0%, #000000 100%);
        font-family: Montserrat, sans-serif;
    }

    .fontheigh {
        font-size: 14px;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 576px) {
    .gameCard img {
        /* height: 120px; */
        height: auto;
    }

    body {
        background: linear-gradient(#495057 0%, #495057 1%, #000000 0%, #000000 100%);
        font-family: Montserrat, sans-serif;
    }

    .fontheigh {
        font-size: 14px;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 991px) {
    .d-md-flex {
        display: none !important;
    }

    body {
        background: linear-gradient(#495057 0%, #495057 1%, #000000 0%, #000000 100%);
        font-family: Montserrat, sans-serif;
    }

    .fontheigh {
        font-size: 14px;
        line-height: 1.5;
    }
}