.Main_Bg {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-image: url('../../assets/images/bg.jpg'); */
    background-image: radial-gradient(circle 50vw at 100% 0, #00fefe, transparent), radial-gradient(circle 50vw at 20% 25vw, #9b00cc, transparent), linear-gradient(180deg, #3f007e, #502cba 25vw, #05122b 50vw);
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8); */
    background-size: cover;
}

.content-section {
    position: relative;
    width: 450px;
    background-color: white;
    height: 100vh;
    margin: 0 auto;
    z-index: 2;
    overflow: scroll;
}

/* width */
.content-section::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

@media only screen and (max-width: 450px) {
    .content-section {
        width: 100%;
    }
}

.adbutton {
    background: #3390ff;
    border: 1px solid #fff;
    border-radius: 9999px;
    color: #fff;
    font-weight: 500;
    line-height: 26px;
    padding: 6px 20px;
    transition: .5s;
}
.mt-4 {
    margin-top: 1.5rem !important;
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.mb-1 {
    margin-bottom: .20rem !important;
}
.ml-1 {
    margin-left: .25rem !important;
}
.mb-3 {
    margin-bottom: 0.3rem !important;
}

.m-footer{
    background-color: var(--dark-bg-color);
    padding: 10px;
}
.m-footer a{
    color: white;
}
.m-header {
    background-color: var(--dark-bg-color);
    color: white;
    padding: 5px;
    height: 70px;
    /* position: fixed; */
    top: 0;
    z-index: 100;
    /* width: 450px; */
}
 

.cent {
    text-align: center;
}

@media only screen and (max-width: 450px) {
    .header {
        width: 100%;
    }
}

:root{
    --dark-bg-color: #252525;
  }
  .custom-heading{
    text-align: center;
    color: #838383b1;
    font-weight: 700;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    letter-spacing: -1px;
  }
  .custom-card{
    width: 100%;
    padding: 10px;
    border: 1px solid #d3d3d3ba;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px lightgray;
    text-align: center;
  }
  .custom-button{
    background: #3390ff;
    /* font-family: 'MV Boli'; */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    transition: 0.5s;
  }
  .custom-button:hover{
    transform: scale(1.02);
  }
  
  
  .ExampleAdSlot{
    height: 250px;
    width: 100px;
  }
  